<template>
  <AModalContent
    :show-close="true"
    class="send-meth"
  >
    <div class="send-meth-title">
      Deposit On-Chain Meth to Meth Bank
    </div>
    <form
      class="send-meth-form"
      method="#"
      @submit.prevent="onSubmit"
    >
      <div class="send-meth-input">
        <label>Amount</label>
        <input
          v-model="amount"
          class="app-input"
          name="amount"
        >
        <div class="send-meth-input-tooltip">On-chain Meth Balance: <b>{{ methBalance }} METH</b></div>

      </div>

      <button
        class="send-meth-button"
        :class="{ disabled: isSending }"
        type="submit"
      >
        <img
          v-if="isSending"
          class="loader"
          src="../../assets/img/spinner-clear.svg"
          width="20"
        > Send
      </button>
    </form>
  </AModalContent>
</template>
<script>

import { depositMethBank } from "@/functions/depositMethBank"
import { formatNumberSpaces } from "@/helpers/format-number-spaces"
import { useWeb3Store } from "@/store/web3"

import AModalContent from "../Modal/AModalContent.vue"

export default {
    components: { AModalContent },

    data: () => ({
        amount: 0,
        wallet: '',
        isSending: false
    }),
    computed: {
      methBalance() {
        return  formatNumberSpaces(useWeb3Store().meth)
      }
    },
    methods: {
        async onSubmit() {
            try {
                this.isSending = true
                await depositMethBank(+this.amount)
                this.$emit('close')
            } catch (err) {
                console.log(err)
            }
            this.isSending = false
        }
    },

}
</script>