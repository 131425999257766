import { useContract } from "@/composables/useContract";
import { useToastTransaction } from "@/composables/useToast/useToastTransaction";
import { Config } from "@/config";
import { useMethBankStore } from "@/store/meth-bank";
import { useWeb3Store } from "@/store/web3";

const abi = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "recipient",
        type: "address",
      },
    ],
    name: "deposit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];
const contractAddress =
  Config.network.name === "sepolia"
    ? "0xc335Acbba67F029B27d38F03E8A5e4d117750aBA"
    : "0xe97F5a38290DbA1AE605194E9b46fbE47Eb08Dd8";
const useDepositMethContract = useContract(abi, contractAddress);
export const depositMethBank = async (amount) => {
  const wallet = useWeb3Store().walletAddress;

  const contract = useDepositMethContract(true);

  const response = await useToastTransaction(
    contract.methods.deposit(+amount, wallet),
    {
      text: `Depositing ${amount} METH to Meth Bank`,
    }
  );
  useWeb3Store().update();
  useMethBankStore().fetchMethBank();
  return response;
};
