<script>
import svgClose from '@/assets/icons/close.svg?inline'
import { defineComponent } from 'vue-demi'

export default defineComponent({
  components: { svgClose },
  props: {
    type: {
      type: String,
      default: 'div'
    },
    headerClass: null,
    showClose: Boolean,
  },

})
</script>

<template>
  <component
    :is="type"
    class="app-modal-content"
  >
    <div
      class="app-modal-header"
      :class="headerClass"
    >
      <a
        v-if="showClose"
        href="javascript:void(0)"
        class="app-modal-close"
        @click.prevent="$emit('close')"
      >
        <svgClose width="30" />
      </a>
    </div>
    <slot />
  </component>
</template>

<style lang="scss">
.app-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
}
.app-modal-content {
  display: block;
  position: absolute;
  box-sizing: border-box;
}
.app-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
