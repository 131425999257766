import { Config } from "@/config";
import { ref } from "vue-demi";

const { useToast } = require("./useToast");

export const useToastTransaction = (transactionConstructor, opts = {}) =>
  new Promise(async (resolve, reject) => {
    const sendOptions = opts.sendOptions || {};
    const { show } = useToast();
    let toastItem;

    const t = transactionConstructor;
    let transaction;
    try {
      const gas = await t.estimateGas(sendOptions);
      transaction = t.send({ gas, ...sendOptions });
    } catch (err) {
      onError(err);
      return;
    }
    transaction.on("sending", () => {
      console.log("sending");
    });

    transaction.on("transactionHash", (hash) => {
      console.log("hash", hash);
      // const link = `${Config.network.scan}/tx/${hash}`;
      toastItem = show({
        type: ref("transaction"),
        duration: -1,
        title: ref("Confirming Transaction..."),

        tx: ref({
          hash,
        }),
        ...opts,
        text: ref(opts.text),
      });
    });
    transaction.on("receipt", (receipt) => {
      console.log("receipt", receipt);
      if (toastItem) {
        toastItem.type.value = "success";
        toastItem.title.value = "Transaction Completed";
        toastItem.tx.value = {
          receipt,
          hash: receipt.transactionHash,
        };
        toastItem.text.value = opts.successText || opts.text;
        toastItem.duration = 8000;
        toastItem.startTimer();
      } else {
        show({
          type: "success",
          title: "Transaction Completed",
          tx: {
            receipt,
            hash: receipt.transactionHash,
          },
          text: opts.successText || opts.text,
          duration: 8000,
        });
      }
      resolve(receipt);
    });
    transaction.on("error", onError);
    function onError(err) {
      let message = err?.message;
      if (err.code === 4001) {
        message = "User rejected the request";
      } else if (err.message.indexOf("insufficient funds") > -1) {
        message = "Your wallet does not have enough funds";
      } else if (err.message.indexOf(": revert") > -1) {
        const cause = err.message.substr(err.message.indexOf(": revert") + 8);
        message = `${cause}`;
      }
      if (toastItem) {
        toastItem.type.value = "error";
        toastItem.title.value = "Transaction Failed";
        toastItem.tx.value = {
          hash: err.transactionHash,
        };
        toastItem.text.value = message;
        toastItem.duration = 8000;
        toastItem.startTimer();
      } else {
        show({
          type: "error",
          title: "Transaction Failed",
          text: message,
        });
      }

      reject(err);
    }
  });
